.fc-button {
  background-color: transparent !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.fc-button:disabled {
  background-color: rgb(200, 200, 200) !important;
}
.fc-button .fc-icon {
  color: rgba(0, 0, 0, 0.87) !important;
}

/* Fix: a bug that the year and month text in the calendar screen breaks line by en,es locale */
.fc .fc-toolbar-title {
  font-size: 1.5em !important;
}
