.splash-icon {
  height: 250px !important;
  width: 250px !important;
  position: fixed !important;
  top: calc(50% - 125px);
  left: calc(50% - 125px);
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
