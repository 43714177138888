.image-thumbnail {
  width: 50px !important;
  display: inline;
  margin: 0 !important;
}

// .icon-button {
//   padding-left: 0 !important;

//   .delete-icon {
//     padding-left: 0;
//     padding-right: 0;
//     // margin-left: -5px;
//   }
// }

.description {
  word-break: break-all;
}

.splash-icon {
  height: 250px !important;
  width: 250px !important;
  position: fixed !important;
  top: calc(50% - 125px);
  left: calc(50% - 125px);
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  z-index: 1201 !important;
}

.backdrop {
  z-index: 1200 !important;
  color: "#fff";
}

.view_description {
  figure {
    overflow-y: scroll;
    margin-inline-start: 10px;
    margin-inline-end: 10px;
  }

  table {
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #b3b3b3 !important;
  }

  table,
  td,
  th {
    border-collapse: collapse;
    border-spacing: 0;
    // width: 100%;
    // height: 100%;
    border: 1px solid #b3b3b3;
    padding: 0.4em;
  }

  table th {
    font-weight: 700;
    background: #f5f5f5;
    min-width: 2em;
    padding: 0.4em;
    border: 1px solid #bfbfbf;
  }
}
