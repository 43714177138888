/* body {
    font-family: "Arial";
    color: #3B3B3B;
    background: #909090;
}

@media (min-width: 768px) {
    .modal-dialog {
        width: 700px;
    }
} */

.panel {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0);
  border-radius: 0;
  background: transparent;
}

.modal-content {
  width: 100% !important;
  margin: 0px auto;
  border: 0px;
  overflow: hidden;
  border-radius: 0%;
}

.modal-content-mobile {
  max-width: 350px;
}

.modal-content-desktop {
  min-width: 700px;
}

@media (max-width: 991px) {
  .modal-content-responsive {
    max-width: 350px;
  }
}
@media (min-width: 992px) {
  .modal-content-responsive {
    min-width: 700px;
  }
}

.login-or {
  position: relative;
  font-size: 16px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-top: 8px;
}

.hr-or {
  color: #cdcdcd;
  background-color: #cdcdcd;
  height: 1px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.panel-left-border {
  border-left-style: solid;
  border-left-color: #f5f5f5;
  border-left-width: 2px;
}

.panel-right-border {
  border-right-style: solid;
  border-right-color: #f5f5f5;
  border-right-width: 2px;
  margin-right: -2px;
}

.span-or {
  display: block;
  position: absolute;
  left: 50%;
  top: -2px;
  margin-left: -25px;
  background-color: #fff;
  text-align: center;
  padding: 0px 5px 0px 5px;
  color: #a3a3a3;
}

.span-or-verical {
  font-size: 16px;
  display: block;
  position: absolute;
  top: 40%;
  margin-left: -25px;
  background-color: #fff;
  text-align: center;
  color: #a3a3a3;
  padding-top: 15px;
  padding-bottom: 15px;
}

.logo-customizable {
  max-width: 60%;
  max-height: 30%;
}

.banner-customizable {
  padding: 25px 0px 25px 0px;
  background-color: lightgray;
}

.label-customizable {
  font-weight: 400;
}

.textDescription-customizable {
  padding-top: 10px;
  padding-bottom: 10px;
  display: block;
  font-size: 16px;
}

.idpDescription-customizable {
  padding-top: 10px;
  padding-bottom: 10px;
  display: block;
  font-size: 16px;
}

.legalText-customizable {
  color: #747474;
  font-size: 11px;
}

.submitButton-customizable {
  font-size: 14px;
  font-weight: bold;
  margin: 20px 0px 10px 0px;
  height: 40px;
  width: 100%;
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

.submitButton-customizable:hover {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}

.errorMessage-customizable {
  padding: 5px;
  font-size: 14px;
  width: 100%;
  background: #f5f5f5;
  border: 2px solid #d64958;
  color: #d64958;
}

.inputField-customizable {
  width: 100%;
  height: 34px;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
}

.inputField-customizable:focus {
  border-color: #66afe9;
  outline: 0;
}

.idpButton-customizable {
  height: 40px;
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
  width: 100%;
}

.idpButton-customizable:hover {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc;
}

.socialButton-customizable {
  border-radius: 2px;
  height: 40px;
  margin-bottom: 15px;
  padding: 1px;
  text-align: left;
  width: 80%;
  font-weight: 600;
}

.redirect-customizable {
  text-align: center;
}

.passwordCheck-notValid-customizable {
  color: #df3312;
}

.passwordCheck-valid-customizable {
  color: #19bf00;
}

.background-customizable {
  background-color: #fff;
}

@media (max-width: 992px) {
  .panels-responsive {
    border: none;
    padding: 0;
    margin: 0;
  }
}
.socialButton-customizable.facebook-button {
  background-color: #4267b2;
  color: white;
}
.socialButton-customizable.facebook-button:hover {
  background-color: #2955ad;
  color: white;
}
.socialButton-customizable.google-button {
  background: #4285f4;
  color: #fff;
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
}
.socialButton-customizable.google-button:hover {
  background: #3f7ce8;
  color: #eee;
}
.socialButton-customizable.amazon-button {
  background-image: linear-gradient(#ffe8ab, #f5c645);
  border: 1px solid #b38b22;
  color: black;
}
.socialButton-customizable.amazon-button:hover {
  background-image: linear-gradient(#ebd498, #e1b144);
  border: 1px solid #b38b22;
}
.socialButton-customizable.apple-button {
  background-color: #000;
  color: #fff;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
}
.socialButton-customizable.apple-button:hover {
  background-color: #000;
  color: #ccc;
}

.socialButton-customizable .social-logo {
  display: inline-block;
  height: 100%;
  padding: 9px;
  text-align: center;
  vertical-align: middle;
}
.socialButton-customizable.facebook-button .social-logo {
  padding: 6px;
}
.socialButton-customizable.google-button .social-logo {
  background: #fff;
  border-radius: 2px;
}
.socialButton-customizable.amazon-button .social-logo {
  padding: 7px;
}
.socialButton-customizable.apple-button .social-logo {
  margin-top: -3px;
  padding: 8px;
}

/* rtl対応 */
.rtl {
  text-align: right;
}
