.label-chip {
  margin-top: 4px !important;
  margin-bottom: 0 !important;
  margin-left: 1px !important;
  margin-right: 1px !important;

  span {
    filter: invert(100%) grayscale(100%) contrast(100);
  }
}

.edit {
  margin-right: 1.4rem;
  margin-left: 4px;
}

.edit-icon {
  position: absolute;
  padding: 0 3px 0 0 !important;
}
.next-to-previous {
  float: right;
  //     margin-top: -10px !important;
  // margin-left: auto !important;
  // margin-right: 0 !important;
  // display: block;
}
