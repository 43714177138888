.image-thumbnail {
  width: 50px !important;
  display: inline;
  margin: 0 !important;
}

// .icon-button {
//   padding-left: 0 !important;

//   .delete-icon {
//     padding-left: 0;
//     padding-right: 0;
//     margin-left: -5px;
//   }
// }

.image-thumbnail-avator {
  width: 50px !important;
  height: 50px !important;
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.54) !important;
  padding: 12px;
}
