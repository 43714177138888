body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* leaflet の レイヤーコントロール */
.leaflet-control-layers-base {
  font-size: 1rem;
}

// CKEditorの背景を透明化
.ck .ck-editor__main > .ck-editor__editable {
  background: transparent !important;
}

div.ck-editor {
  margin-top: 0 !important; // CKEditorの上部マージンを無くす

  .ck.ck-toolbar {
    border: 0 !important; // CKEditorのborderを見えなくする
  }
  .ck.ck-editor__editable_inline.ck-read-only {
    border: 0 !important; // CKEditorのborderを見えなくする

    > :first-child {
      margin-top: 0px;
    }
    > :last-child {
      margin-bottom: 0px;
    }
  }
}

//ログインメニューのaタグ文字色変更
#lang-menu {
  a {
    color: rgb(28, 33, 73) !important;
  }
}

//timelineのラベルを小さくする
#timeline_root .MuiChip-root {
  font-size: 0.5rem;
  height: 18px;
  .MuiChip-label {
    padding-left: 4px;
    padding-right: 4px;
  }
}

//記事編集のmarkdown切り替えのスペースを小さくする
label.switch_html {
  margin-top: -2px;
  margin-right: 8px;
  margin-bottom: -10px;
  margin-left: 0px;
}

//view_location_panelの上下均等揃え
#view_location_panel {
  display: flex;
  align-items: center;
}

#login_page .carousel .slide .legend {
  opacity: 1;
}

// 画像ダイアログを縦スクロール可能にする
body > div.MuiDialog-root {
  overflow-y: scroll;
}

// description表示を2行までにする
div.view_description {
  // description表示のスタイル変更
  width: 100%;

  &.summary {
    // 2行までにする
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  p {
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
  }
  h2 {
    font-size: 1.4em;
  }
  h2,
  h3,
  h4 {
    margin-block-start: 0.25em;
    margin-block-end: 0;
  }
  ul,
  ol {
    margin-block-start: 0.25em;
    margin-block-end: 0.25em;
  }
  pre {
    padding-top: 1em;
    padding-bottom: 0.25em;
    margin-block-start: 0.25em;
    margin-block-end: 0.25em;
  }
  blockquote {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
  }
}

// カレンダーのイベントの文字色にフィルター
#root .fc.fc-media-screen .fc-event-title-container div.fc-event-title {
  filter: invert(100%) grayscale(100%) contrast(100) !important;
}
