.card-media {
  object-fit: cover !important;
}
.card-action-area {
  color: rgba(0, 0, 0, 0.54) !important;
  text-align: center !important;
  // height: 160px;
  display: flex;
  align-items: center;
  // width: 50vw !important;
}
