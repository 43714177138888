.App {
  text-align: center;
}

.global-header {
  background-color: #ffffff;
}

.App-header {
  // background-color: #ccdddd;
  background-color: #ffffff;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

  .App-logo {
    height: 60vmin;
    pointer-events: none;
  }

  .App-subtitle {
    margin-block-start: 10px;
    margin-block-end: 21.9px;
  }
}

.App-link {
  color: #61dafb;
}

.App-Main {
  // background-color: #ccdddd;
  background-color: #ffffff;
  min-height: 50vh;
  .image-area {
    text-align: center;
    img {
      width: 95%;
    }
  }
  .carousel-area {
    direction: initial;
    @media screen and (max-width: 767px) {
      margin-left: 10vw;
      margin-right: 10vw;
    }
  }
  .text-area {
    margin-left: 5vw;
    margin-right: 5vw;
  }

  p.MuiTypography-root {
    color: rgb(28, 33, 73);
    @media screen and (max-width: 767px) {
      font-weight: 700 !important;
      font-size: 1.25rem !important;
    }
  }
}

.App-Container {
  background-color: #ffffff;
}

.App-footer {
  // background-color: #ccdddd;
  background-color: #ffffff;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 2vmin);
  color: white;
}

//スクロールアイコン
.scroll {
  display: inline-block;
  padding-top: 70px;
  position: relative;
}
.scroll::before {
  animation: scroll 3.5s infinite;
  border: solid #777777;
  border-width: 0 0 1px 1px;
  content: "";
  display: inline-block;
  margin: auto;
  margin-left: -8px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  transform: rotate(-45deg);
  width: 20px;
  height: 20px;
}
@keyframes scroll {
  0% {
    transform: rotate(-45deg) translate(0, 0);
  }
  80% {
    transform: rotate(-45deg) translate(-30px, 30px);
  }
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

.login-privacypolicy {
  color: #1c2149 !important;
  // color: white !important;
  font-size: 0.8rem;
}
/* add inline style */

.important-white {
  color: white !important;
  background: Black;
  display: inline-block;
  height: 100%;
  margin-right: 12px;
  padding: 9px;
  text-align: center;
  vertical-align: middle;
}

.mail-button {
  background-color: #db4437;
  color: #fff;
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
}
.social-button {
  border-radius: 2px;
  height: 40px;
  margin-bottom: 15px;
  padding: 1px;
  text-align: left;
  width: 100%;

  margin-bottom: 0;
  font-size: 14px;
  font-weight: 600 !important;
  /* text-align: center; */
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
}
.social-logo {
  display: inline-block;
  height: 100%;
  margin-right: 12px;
  padding: 9px;
  text-align: center;
  vertical-align: middle;
}
.social-logo-icon {
  font-size: 15px !important;
}
