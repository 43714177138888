//ckeditorのスタイル変更
.ck-editor__main {
  p {
    margin: 0;
  }
  h2 {
    font-size: 1.4em;
  }
  h2,
  h3,
  h4 {
    margin-block-start: 0.25em;
    margin-block-end: 0;
  }
  ul,
  ol {
    margin-block-start: 0.25em;
    margin-block-end: 0.25em;
  }
  pre {
    padding-top: 1em;
    padding-bottom: 0.25em;
    margin-block-start: 0.25em;
    margin-block-end: 0.25em;
  }
  blockquote {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
  }
}

.ck-editor {
  margin-top: 10px !important;
  // margin-bottom: 5px !important;
}
